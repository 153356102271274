// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("@hotwired/turbo-rails")
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("@rails/actiontext")
require("local-time").start()
require("@nathanvda/cocoon")
require("trix")

window.Rails = Rails

import "channels"
import "controllers"
import "bootstrap"
import "data-confirm-modal"

import "src/actiontext"

$(document).on("turbo:load", () => {
	$('[data-toggle="tooltip"]').tooltip()
	$('[data-toggle="popover"]').popover()
})

// Dropdown do not close on Switch click
$(document).on("turbo:load", () => {
	$('.dropdown-menu-stay').click(function(e) {
		e.stopPropagation();
	});
})

$(document).on("turbo:load", () => {
	$('.toast').toast('show').on('hidden.bs.toast', function() {
		$('.toast').remove();
	});
})

// Dropdown submenu
$(document).on("turbo:load", () => {
	$('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
		if (!$(this).next().hasClass('show')) {
			$(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
		}

		var $subMenu = $(this).next('.dropdown-menu');
		$subMenu.toggleClass('show');

		$(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
			$('.dropdown-submenu .show').removeClass('show');
		});

		return false;
	});
})

dataConfirmModal.setDefaults({
	commit: 'Continue',
	cancel: 'Cancel',
	commitClass: 'btn-outline-danger',
	cancelClass: 'btn-outline-primary'
});
