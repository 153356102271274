import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = ['drawer']

	toggle() {
		if (this.hasDrawerTarget) {
			$(this.drawerTarget).toggleClass('open')
		}
	}
}
