import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = ['card', 'error', 'token', 'form', 'zip', 'country']

	connect() {
		this.stripe = Stripe(this.stripeKey)
		const elements = this.stripe.elements()
		this.card = elements.create('card', this.cardElementOptions)
		this.card.mount(this.cardTarget)
		this.card.on('change', this.onChange.bind(this))
	}

	onChange(event) {
		if (event.error) {
			this.errorTarget.textContent = event.error.message
		} else {
			this.errorTarget.textContent = ''
		}
	}

	submit(event) {
		event.preventDefault()
		Rails.disableElement(this.formTarget)
		this.createStripeToken()
	}

	createStripeToken() {
		this.stripe.createToken(this.card, this.stripeTokenOptions).then(result => {
			if (result.error) {
				this.showError(result.error.message)
			} else {
				this.handleStripeToken(result.token)
			}
		})
	}

	handleStripeToken(token) {
		this.errorTarget.textContent = ''
		this.tokenTarget.value = token.id
		this.formTarget.submit()
	}

	showError(error) {
		this.errorTarget.textContent = error
		setTimeout(() => Rails.enableElement(this.formTarget), 100)
	}

	get stripeKey() {
		return document.querySelector('meta[name="stripe-key"]').content
	}

	get cardElementOptions() {
		return { hidePostalCode: this.hasZipTarget }
	}

	get stripeTokenOptions() {
		const options = {}

		if (this.hasZipTarget) {
			options.address_zip = this.zipTarget.value
		}

		if (this.hasCountryTarget) {
			options.address_country = this.countryTarget.value
		}

		return options
	}
}
