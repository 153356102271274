import { Controller } from '@hotwired/stimulus'
import EasyAutocomplete from 'easy-autocomplete';

export default class extends Controller {
	static targets = ['autocomplete']

	static values = {
		url: String,
		name: String
	}

	connect() {
		if (this.hasAutocompleteTarget) {
			this.initializeEasyAutocomplete()
		}
	}

	initializeEasyAutocomplete() {
		let _this = this

		$(this.autocompleteTarget).easyAutocomplete({
			getValue: _this.nameValue,
			requestDelay: 500,

			url: function(phrase) {
				return `${_this.urlValue}.json?term=${phrase}`
			},

			categories: [
				{
					listLocation: "clients",
					header: '<div class="dropdown-header pl-1">CLIENTS</div>',
				},
				{
					listLocation: "issues",
					header: '<div class="dropdown-header pl-1">REQUESTS</div>',
				},
			],

			list: {
				maxNumberOfElements: 15,

				match: {
					enabled: true
				},

				onChooseEvent: this.onChooseEvent.bind(this),
				onShowListEvent: this.onShowListEvent.bind(this),
				onHideListEvent: this.onHideListEvent.bind(this)
			}
		})
	}

	onChooseEvent() {
		$('.easy-autocomplete-container').trigger('hide.eac')

		let url = $(this.autocompleteTarget).getSelectedItemData().url
		if (url) {
			Turbo.visit(url)
		}
	}

  onShowListEvent() {
		$('body').addClass('autocomplete-backdrop')
	}

	onHideListEvent() {
		$('body').removeClass('autocomplete-backdrop')
	}
}
